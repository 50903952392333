import React from "react";
import "../CSS/Cards.css";

export default function Cards() {
  return (
    <section id="cards">
      <div className="container pt-5">
        <div className="row my-5"></div>
        <div className="row">
          <div className="col-lg-1 col-6 mx-auto mb-3">
            <p
              style={{ fontSize: 25, lineHeight: 1.5 }}
              className="section-title"
            >
              Cards
            </p>
          </div>
          <div className="col-lg-10 col-12 mx-auto text-start">
            <div className="row my-5">
              <div className="col-lg-4 col-12 mx-auto">
                <div className="row">
                  <div className="col-lg-12 mb-4 col-12 text-center mx-auto">
                    <img
                      height={200}
                      src={require("../Images/suits/card n5.png")}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-12 col-12 text-center mx-auto">
                    <h4 className="cardTitle">COMMUNITY CARD</h4>
                    <ul className="cardsList">
                      <li>Access to the Ace club</li>
                      <li>Voting for the Ace Of Venture</li>
                      <li>Ability to send card to burning pit versus reward</li>
                      <li>Ability to stake for rewards</li>
                      <li>
                        Coming soon: Fusing with another card to generate AoV
                        $Token
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="row">
                  <div className="col-lg-12 mb-4 col-12 text-center">
                    <img
                      height={200}
                      src={require("../Images/cards/jack.png")}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-12 col-12 text-center">
                    <h4 className="cardTitle">JACK</h4>

                    <ul className="cardsList">
                      <li>
                        Your ticket for the Aces Of Venture Challenge is the
                        Jack.
                      </li>
                      <li>
                        To participate, you must send your card to the burning
                        pit (Completely destroy your NFT)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="row">
                  <div className="col-lg-12 mb-4 col-12 text-center">
                    <img
                      height={200}
                      src={require("../Images/cards/king card.png")}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-12 col-12 text-center">
                    <h4 className="cardTitle">KING</h4>

                    <ul className="cardsList">
                      <li>Royalty cards are part of the mastermind group</li>
                      <li>
                        They vote for whoever can participate in the Aces Of
                        Venture Challenge
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5">
              <div className="col-lg-4 col-12">
                <div className="row">
                  <div className="col-lg-12 mb-4 col-12 text-center">
                    <img
                      height={200}
                      src={require("../Images/cards/queen.png")}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-12 col-12 text-center">
                    <h4 className="cardTitle">QUEEN</h4>

                    <ul className="cardsList">
                      <li>
                        Queens are so rare in the metaverse that in this
                        collection they are superior to the Kings
                      </li>
                      <li>
                        They hold all the perks of Royalty, and are the only
                        ones that can ask questions during the challenge
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="row">
                  <div className="col-lg-12 mb-4 col-12 text-center">
                    <img
                      height={200}
                      src={require("../Images/suits/question mark.png")}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-12 col-12 text-center">
                    <h4 className="cardTitle">JOKER</h4>

                    <ul className="cardsList">
                      <li>
                        The Jokers are completely unique, only 8 of them are
                        available.
                      </li>
                      <li>They share the same perks the royalty</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="row">
                  <div className="col-lg-12 mb-4 col-12 text-center">
                    <img
                      height={200}
                      src={require("../Images/suits/question mark.png")}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-12 col-12 text-center">
                    <h4 className="cardTitle">ACE</h4>

                    <ul className="cardsList">
                      <li>
                        The only way to get an Ace Of Venture card, is to
                        participate and win the challenge
                      </li>
                      <li>
                        Not only the winner will get the prize but an invaluable
                        business card to show off that says:{" "}
                        <p className="m-0">I’ve already won</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-5"></div>
      </div>
    </section>
  );
}
