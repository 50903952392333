import React, { useEffect } from "react";
import "../CSS/NavBar.css";

export default function NavBar() {
  return (
    <nav
      className="navbar navbar-dark navbar-expand-md justify-content-center py-3"
      style={{
        backgroundColor: "#000000",
        borderBottomStyle: "solid",
        borderBottomWidth: 3,
        borderBottomColor: "white",
      }}
    >
      <div className="container justify-content-between sticky-top">
        <div className="navbar-brand d-md-none d-block d-sm-block">
          <img src={require("../Images/logo.jpg")} style={{ height: 50 }} />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsingNavbar3"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse w-100" id="collapsingNavbar3">
          <ul className="nav navbar-nav ms-auto w-100 justify-content-start">
            <li className="nav-item mx-4">
              <a className="nav-link text-white" href="#about">
                About
              </a>
            </li>
            <li className="nav-item mx-4">
              <a className="nav-link text-white" href="#suits">
                Suits
              </a>
            </li>
            <li className="nav-item mx-4">
              <a className="nav-link text-white" href="#cards">
                Cards
              </a>
            </li>
          </ul>
          <ul
            className="d-md-block d-none d-sm-none nav navbar-nav ms-auo w-100 justify-between"
            style={{ justifyContent: "center" }}
          >
            <li className="nav-item">
              <img
                src={require("../Images/logo.jpg")}
                style={{ height: 100 }}
              />
            </li>
          </ul>
          <ul className="nav navbar-nav ms-auto w-100 justify-content-end">
            <li className="nav-item mx-4">
              <a className="nav-link text-white" href="#team">
                Team
              </a>
            </li>
            <li className="nav-item mx-4">
              <a className="nav-link text-white" href="#faq">
                FAQ
              </a>
            </li>
            <li className="nav-item mx-4">
              <a
                className="nav-link text-dark btn bg-light"
                href="https://discord.gg/WjRPwjtBqC"
                target={"_blank"}
              >
                Discord
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
