import React from "react";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import Web3 from "web3";
import { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-modal";
import "../../CSS/mintpage.css";

const CONT_ADD = "0xc3c520b650f355d31e7b62a77e6d9b65355f0a91";
const ABI = require("../../ABI/contract.json");
const WHITELIST = require("../../ABI/whitelist.json");

export const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "Web 3 Modal Demo",
      infuraId: "https://rinkeby.infura.io/v3/29607741fdc34d609802f64f74c183bf",
    },
  },
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId: "https://rinkeby.infura.io/v3/29607741fdc34d609802f64f74c183bf",
    },
  },
};

export default function MintPage() {
  const [congratsModalOpen, setcongratsModalOpen] = useState(false);
  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [network, setNetwork] = useState();
  const [chainId, setChainId] = useState();
  const [isMinting, setIsMinting] = useState();
  const [isCorrectChain, setCorrectChain] = useState();
  const [ReachedLimit, setReachedLimit] = useState(false);
  const [counterMint, setCounterMint] = useState(1);
  const [tokensInWallet, setTokensInWallet] = useState(0);
  const [maxTokensCanBeMinted, setMaxTokensCanBeMinted] = useState(3);
  const [mintPrice, setMintPrice] = useState(0);

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
  };

  const disconnect = useCallback(async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
    window.location.reload(false);
  });

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        if (accounts) {
          if (
            WHITELIST.filter(
              (x) =>
                x.address.toLocaleLowerCase() ===
                accounts[0].toLocaleLowerCase()
            ).length > 0
          ) {
            checkIfReachedLimit(provider, accounts[0]);
            setWhiteListStat(1);
          } else {
            setWhiteListStat(0);
          }
          setAccount(accounts[0]);
        }
      };

      const handleChainChanged = (_hexChainId) => {
        if (_hexChainId !== chainId) {
          setCorrectChain(_hexChainId === 4);
          setChainId(_hexChainId);
        }
      };

      const handleDisconnect = () => {
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [disconnect, provider]);

  const web3Modal = new Web3Modal({
    providerOptions, // required
  });

  const [IsContractChecked, SetContractChecked] = useState(false);
  const [isContractedPaused, setContractPaused] = useState(false);
  const [isContractPresale, setIsContractPresale] = useState(null);
  const [WhitelistStat, setWhiteListStat] = useState(null);

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setNetwork(network);
      setChainId(network.chainId);
      setCorrectChain(network.chainId === 4);
      await getMintPrice(provider);
      await checkIfPaused(provider);
      let answer = await checkIfPresale(provider);
      await checkIfReachedLimit(provider, accounts[0], answer);

      if (provider && WhitelistStat === null) {
        if (WHITELIST.filter((x) => x.address === accounts[0]).length > 0) {
          setWhiteListStat(1);
        } else {
          setWhiteListStat(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkIfPaused = async (provider) => {
    const answer = await isPaused(provider);
    if (answer) {
      setContractPaused(true);
    }
    SetContractChecked(true);
  };

  const getMintPrice = async (provider) => {
    const answer = await getPriceContract(provider);
    setMintPrice(Web3.utils.fromWei(answer));
  };

  const checkIfPresale = async (provider) => {
    const answer = await isPresale(provider);
    if (answer) {
      setIsContractPresale(true);
    } else {
      setIsContractPresale(false);
    }
    return answer;
  };

  const checkIfReachedLimit = async (provider, address, ispresaleResp) => {
    const answer = await hasReachedLimit(provider, address, ispresaleResp);
    if (answer) {
      setReachedLimit(true);
    } else {
      setReachedLimit(false);
    }
  };
  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x4" }],
      });
      window.location.reload();
    } catch (switchError) {
      console.log("error " + switchError);
    }
  };

  let ButtonView = null;

  if (provider) {
    if (network) {
      if (isCorrectChain) {
        if (!IsContractChecked) {
          ButtonView = <p>Loading...</p>;
        } else {
          if (isContractedPaused) {
            ButtonView = <p className="my-5">Minting is Not Available</p>;
          } else {
            if (WhitelistStat === 1) {
              if (ReachedLimit) {
                ButtonView = <p className="my-5">Minting Limit Reached</p>;
              } else {
                ButtonView = (
                  <>
                    <button
                      disabled={isMinting}
                      className="btn px-5 py-3 btn-success text-dark"
                      style={{
                        backgroundColor: "white",
                        fontSize: "40px",
                        borderRadius: "15px",
                        fontWeight: "900",
                        borderWidth: "0px",
                      }}
                      onClick={() => {
                        Mint(provider, account);
                      }}
                    >
                      <span style={{ color: "black", padding: "30px" }}>
                        MINT
                      </span>
                    </button>
                    <p>Mint Price : {mintPrice}</p>
                    <div class="container">
                      <div class="row my-3">
                        <div class="col-lg-2 col-6 mx-auto">
                          <div class="row">
                            <div class="col-4">
                              <button
                                disabled={isMinting}
                                className="btn btn-danger"
                                onClick={() => {
                                  let newCounter = counterMint;
                                  newCounter--;
                                  newCounter = Math.max(newCounter, 1);
                                  newCounter = Math.min(
                                    newCounter,
                                    maxTokensCanBeMinted
                                  );
                                  setCounterMint(newCounter);
                                }}
                              >
                                -
                              </button>
                            </div>
                            <div class="col-4">
                              <h3>{counterMint}</h3>
                            </div>
                            <div class="col-4">
                              <button
                                disabled={isMinting}
                                className="btn btn-light"
                                onClick={() => {
                                  let newCounter = counterMint;
                                  newCounter++;
                                  newCounter = Math.max(newCounter, 1);
                                  newCounter = Math.min(
                                    newCounter,
                                    maxTokensCanBeMinted
                                  );
                                  setCounterMint(newCounter);
                                }}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            } else {
              if (WhitelistStat === 0) {
                ButtonView = <span>You are not Whitelisted</span>;
              }
            }
          }
        }
      } else {
        ButtonView = (
          <button className="btn btn-light text-dark" onClick={switchNetwork}>
            Switch Network
          </button>
        );
      }
    }
  } else {
    ButtonView = (
      <button
        className="btn px-3 py-3 btn-success text-dark"
        style={{
          backgroundColor: "white",
          fontSize: "40px",
          borderRadius: "15px",
          fontWeight: "900",
          borderWidth: "0px",
        }}
        onClick={connectWallet}
      >
        <span style={{ color: "black" }}>Connect Wallet</span>
      </button>
    );
  }

  function openCongratsModal() {
    setcongratsModalOpen(true);
  }

  function closeCongratsModal() {
    setcongratsModalOpen(false);
    window.location.reload(false);
  }

  return (
    <div className="container">
      <Modal
        className={"Modal"}
        isOpen={congratsModalOpen}
        contentLabel="Minimal Modal Example"
        style={{ margin: "20%" }}
      >
        <div class="row text-center">
          <div class="col">
            <h2 className="mb-5">🎉 Congratulations ! 🎉</h2>
            <h2 className="my-4">Thank you for minting AoV!</h2>
            <p className="mb-5">Ready to become an ACE ?</p>
            <button className="btn btn-light" onClick={closeCongratsModal}>
              Continue
            </button>
          </div>
        </div>
      </Modal>

      <div class="row mt-5">
        <div class="col text-start">
          <Link to={"/"} className="btn btn-light">
            Back
          </Link>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4 col-12 mx-auto">
          <img
            style={{ width: "100%" }}
            src={require("../../Images/logo.jpg")}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col text-center">{ButtonView}</div>
      </div>

      <div className="row mt-1">
        {isMinting ? <p className="mb-2">minting is progress...</p> : ""}

        {provider &&
        IsContractChecked &&
        !isContractedPaused &&
        WhitelistStat === 1 ? (
          <>
            <p>You have {tokensInWallet} AoV Tokens</p>
          </>
        ) : (
          <span></span>
        )}
      </div>
      <div class="row mt-2">
        <div class="col">
          {provider ? (
            <button onClick={disconnect} className="btn btn-danger">
              Logout
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row my-5"></div>
      <div className="row my-5"></div>
    </div>
  );

  async function Mint(provider, address) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const referral = params.get("referral");

    setIsMinting(true);
    const library = new ethers.providers.Web3Provider(provider);
    const network = await library.getNetwork();
    if (network.chainId !== 4) {
      window.location.reload(false);
      return;
    }
    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD, {
      from: address,
    });
    let userObject = WHITELIST.filter(
      (x) => x.address === "0xc3f837265dFE3c3020fd774695690bCEcc0e8D65"
    );
    let signature = null;
    if (userObject.length > 0) {
      let utf8Encode = new TextEncoder();
      signature = web3.utils.numberToHex(userObject[0].hash);
    }
    try {
      let numberOfTokensToMint = counterMint;
      numberOfTokensToMint = Math.max(numberOfTokensToMint, 1);
      numberOfTokensToMint = Math.min(numberOfTokensToMint, 3);

      if (isContractPresale) {
        let resp = await contract.methods
          .whitelistMinting(numberOfTokensToMint, signature)
          .send(mintPrice);
        if (resp.status === true) {
          setIsMinting(false);
          openCongratsModal();
        }
      } else {
        let resp = await contract.methods
          .publicMint(numberOfTokensToMint, referral)
          .send(mintPrice);
        if (resp.status === true) {
          setIsMinting(false);
          openCongratsModal();
        }
      }
    } catch (e) {
      setIsMinting(false);
    }
  }

  async function isPaused(provider) {
    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD);
    let resp = await contract.methods.paused().call();
    return resp;
  }

  async function getPriceContract(provider) {
    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD);
    let resp = await contract.methods.price().call();
    return resp;
  }

  async function isPresale(provider) {
    let web3 = new Web3(provider);
    const contract = new web3.eth.Contract(ABI, CONT_ADD);
    let resp = await contract.methods.presale().call();
    return resp;
  }

  async function hasReachedLimit(provider, address, ispresaleResp) {
    if (window.ethereum) {
      let web3 = new Web3(provider);
      const contract = new web3.eth.Contract(ABI, CONT_ADD);
      let resp = await contract.methods.getAux(true, address).call();
      let limit = 0;
      if (ispresaleResp) {
        limit = await contract.methods.presaleWalletLimit().call();
      } else {
        limit = await contract.methods.publicsalewalletlimit().call();
      }
      setTokensInWallet(resp);
      setMaxTokensCanBeMinted(limit - resp);
      let newCounter = counterMint;
      newCounter = Math.max(newCounter, 1);
      newCounter = Math.min(newCounter, limit - resp);
      setCounterMint(newCounter);
      return resp >= limit;
    } else {
      return true;
    }
  }
}
