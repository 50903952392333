import React from "react";
import "../CSS/Section.css";
import "../CSS/About.css";
import { isMobile } from "../Utils/Resolution";

export default function About() {
  return (
    <section id="about">
      <div className="container pt-5">
        <div className="row my-5"></div>
        <div className="row">
          {/* <div className="col-lg-1 col-6 mx-auto mb-3">
            <p
              style={{ fontSize: 25, lineHeight: 1.5 }}
              className="section-title"
            >
              about
            </p>
          </div> */}
          <div className="col-lg-10 col-12 mx-auto text-lg-start text-center">
            <p style={{ fontSize: 25, lineHeight: isMobile ? 2 : 3 }}>
              For the common people, entrepreneurship is the equivalent of
              innovation, success, prestige, and wealth… What these outsiders do
              not realize is that for us, it’s a game…{" "}
            </p>
            <p style={{ fontSize: 25, lineHeight: isMobile ? 2 : 3 }}>
              In that <span style={{ color: "#78b7e5" }}>EXTREMELY</span>{" "}
              difficult game, we thrive, we fight, we plan, we never sleep… we
              start as challengers and do not stop until we become{" "}
              <span style={{ color: "#78b7e5" }}>Aces</span>.
            </p>
          </div>
        </div>
        <div className="row py-5"></div>
      </div>
    </section>
  );
}
