import React from "react";
import SocialButton from "../Components/SocialButton";
import "../CSS/Section.css";
import { isMobile } from "../Utils/Resolution";
import "../CSS/footer.css";

export default function Footer() {
  const socialData = [
    {
      image: require("../Images/Social/discord.png"),
      link: "https://discord.com/invite/WjRPwjtBqC",
    },
    {
      image: require("../Images/Social/twitter.png"),
      link: "https://twitter.com/acesofventures/",
    },
    {
      image: require("../Images/Social/youtube.png"),
      link: "https://www.youtube.com/channel/UCJjCujy4W_1K_IfxkXQKsFA",
    },
  ];
  return (
    <section id="footer">
      <div className="container pt-5">
        <div className="row my-lg-5 my-0"></div>
        <div className="row">
          <div class="col-lg-3 col-12 mx-auto">
            <p>© 2021 Aces of Ventures.</p>
            <p>All rights reserved.</p>
          </div>
          <div class="col-lg-3 col-12 mx-auto text-center">
            <img src={require("../Images/logo.jpg")} style={{ height: 100 }} />
          </div>
          <div class="col-lg-3 col-12 mx-auto my-5">
            <div class="row">
              {socialData.map((data, index) => {
                return (
                  <div class="col-lg-3 col-3 mx-auto " key={index}>
                    <SocialButton link={data.link} image={data.image} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row py-5"></div>
      </div>
    </section>
  );
}
