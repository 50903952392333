import React from "react";
import GoogleSheetsForm from "../../Components/GoogleSheetsForm";
import NavBar from "../../Components/NavBar";
import Seperator from "../../Components/Seperator";
import About from "../About";
import Cards from "../Cards";
import FAQ from "../FAQ";
import Footer from "../Footer";
import Home from "../Home";
import Mailing from "../Mailing";
import Roadmap from "../Roadmap";
import Suits from "../Suits";
import Team from "../Team";

export default function Main() {
  return (
    <>
      <NavBar />
      {/* <NavTest /> */}
      <Home />
      <Seperator />
      <About />
      <Seperator />
      <Mailing />
      <Seperator />
      <Suits />
      <Seperator />
      <Cards />
      {/* <Seperator /> */}
      {/* <Roadmap /> */}
      {/* <Seperator /> */}
      {/* <Speakers /> */}
      <Seperator />
      <Team />
      <Seperator />
      <FAQ />
      <Seperator />
      <Footer />
    </>
  );
}
