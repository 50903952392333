import React from "react";

export default function Mailing() {
  return (
    <div class="container-fluid my-5">
      <div class="row mb-5">
        <h1>Join us to stay updated !</h1>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12 mx-auto">
          <form
            method="post"
            class="af-form-wrapper"
            accept-charset="UTF-8"
            action="https://www.aweber.com/scripts/addlead.pl"
          >
            <div style={{ display: "none" }}>
              <input type="hidden" name="meta_web_form_id" value="1497604191" />
              <input type="hidden" name="meta_split_id" value="" />
              <input type="hidden" name="listname" value="awlist6241173" />
              <input
                type="hidden"
                name="redirect"
                value="https://discord.gg/WjRPwjtBqC"
                id="redirect_e280d565cc737bbefd8d9f0d44dedfda"
              />
              <input
                type="hidden"
                name="meta_redirect_onlist"
                value="https://discord.gg/WjRPwjtBqC"
              />
              <input type="hidden" name="meta_adtracking" value="My_Web_Form" />
              <input type="hidden" name="meta_message" value="1" />
              <input type="hidden" name="meta_required" value="name,email" />

              <input type="hidden" name="meta_tooltip" value="" />
            </div>
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-5 col-12">
                  <div class="mb-3">
                    <input
                      required
                      placeholder="Name"
                      type="text"
                      name="name"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-5 col-12">
                  <div class="mb-3">
                    <input
                      required
                      name="email"
                      placeholder="Email Address"
                      type="email"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-2 col-12">
                  <button
                    name="submit"
                    type="submit"
                    value="Submit"
                    class="btn btn-success text-light"
                  >
                    JOIN
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
