import React from "react";
import TeamCard from "../Components/TeamCard";
import "../CSS/Team.css";

export default function Team() {
  return (
    <section id="team">
      <div className="container pt-5">
        <div className="row my-5"></div>
        <div className="row">
          <div className="col-6 mx-auto mb-3 col-lg-1">
            <p
              style={{ fontSize: 25, lineHeight: 1.5 }}
              className="section-title"
            >
              Team
            </p>
          </div>
          <div
            className="col-lg-10 col-12 mx-auto text-lg-start text-center"
            style={{
              padding: 20,
            }}
          >
            <div className="row my-5 text-center">
              <div class="col-3 d-none d-lg-block"></div>
              <div className="col-lg-3 col-10 mx-auto my-3">
                <TeamCard
                  image={require("../Images/team/ANDRE.png")}
                  name="Andre"
                  insta="https://instagram.com/andreabiawad"
                  linkedin="https://linktr.ee/andreabiawad"
                />
              </div>
              <div className="col-lg-3 col-10 mx-auto my-3">
                <TeamCard
                  image={require("../Images/team/robert.png")}
                  name="Robert"
                  insta="https://www.instagram.com/robert_tabet/"
                  linkedin="https://www.linkedin.com/in/robert-tabet-29373685/"
                />
              </div>
              <div class="col-3 d-none d-lg-block"></div>
            </div>
            <div className="row text-center">
              <div class="col-1 d-none d-lg-block"></div>
              <div className="col-lg-3 col-10 mx-auto my-3">
                <TeamCard
                  image={require("../Images/team/ALAIN.png")}
                  name="Alain"
                  linkedin="https://www.linkedin.com/in/alain-farah-9325b6a/"
                />
              </div>
              <div className="col-lg-3 col-10 mx-auto my-3">
                <TeamCard
                  image={require("../Images/team/GHAZI.png")}
                  name="Ghazi"
                  linkedin="https://www.linkedin.com/in/ghazi-el-yaman-295b0b187/"
                  insta="https://www.instagram.com/ghaziyaman/"
                />
              </div>
              <div className="col-lg-3 col-10 mx-auto my-3">
                <TeamCard
                  image={require("../Images/team/jad.png")}
                  name="Jad"
                  linkedin="https://www.linkedin.com/in/jad-barada-1056a1a7/"
                  insta="https://www.instagram.com/jadbarada1/"
                />
              </div>
              <div class="col-1 d-none d-lg-block"></div>
            </div>
            <div className="row my-5 text-center">
              <div class="col-1 d-none d-lg-block"></div>

              <div className="col-lg-3 col-10 mx-auto my-3">
                <TeamCard
                  image={require("../Images/team/jimmy.png")}
                  name="Jimmy"
                  insta="https://www.instagram.com/jimmy_chaaya/"
                />
              </div>
              <div className="col-lg-3 col-10 mx-auto my-3">
                <TeamCard
                  image={require("../Images/team/NICOLAS.png")}
                  name="Nicolas"
                  linkedin="https://www.linkedin.com/in/nicolas-dahan-7b113115/"
                />
              </div>
              <div className="col-lg-3 col-10 mx-auto my-3">
                <TeamCard
                  image={require("../Images/team/TEENA.png")}
                  name="Teena"
                  insta="https://www.instagram.com/teena.helou/"
                />
              </div>
              <div class="col-1 d-none d-lg-block"></div>
            </div>
          </div>
        </div>
        <div className="row py-5"></div>
      </div>
    </section>
  );
}
