import React from "react";
import "../CSS/Suits.css";

export default function Suits() {
  return (
    <section id="suits">
      <div className="container pt-5">
        <div className="row my-5"></div>
        <div className="row">
          <div className="col-6 mx-auto mb-3 col-lg-1">
            <p
              style={{ fontSize: 25, lineHeight: 1.5 }}
              className="section-title"
            >
              Suits
            </p>
          </div>
          <div className="col-lg-10 col-12 mx-auto text-lg-start text-center">
            <div className="row my-5">
              <div className="col-6 suitDisplay">
                <div className="row">
                  <div className="col-lg-5 col-xl-4 col-12">
                    {" "}
                    <img
                      height={200}
                      src={require("../Images/suits/ace.png")}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-7 col-xl-8 col-12">
                    <p className="suitText">
                      <span className="py-lg-0 py-2">The Techies</span> They
                      already live in the virtual world… the metaverse? Not only
                      were they the first to join it… They are the ones creating
                      and shaping it…
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 suitDisplay">
                <div className="row">
                  <div className="col-lg-5 col-xl-4 col-12">
                    {" "}
                    <img
                      height={200}
                      src={require("../Images/suits/ACE-DIAMOND.png")}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-7 col-xl-8 col-12">
                    <p className="suitText">
                      <span className="py-lg-0 py-2">The hustlers</span> It’s in
                      their blood, in their genes… They are BORN businessmen.
                      This breed will work on any project…. and will make it a
                      success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 suitDisplay">
                <div className="row order-first">
                  <div className="col-lg-7 col-xl-8 col-12 order-lg-0 order-1">
                    <p className="suitText">
                      <span className="py-lg-0 py-2">
                        The Social Entrepreneurs
                      </span>{" "}
                      Sure, they are here on business, but on their quest for
                      grandeur, their social impact will be heard.
                    </p>
                  </div>
                  <div className="col-lg-5 col-xl-4 col-12 order-lg-1 order-0">
                    {" "}
                    <img
                      height={200}
                      src={require("../Images/suits/ACE-HEART.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>{" "}
              <div className="col-6 suitDisplay">
                <div className="row">
                  <div className="col-lg-7 col-xl-8 col-12 order-lg-0 order-1">
                    <p className="suitText">
                      <span className="py-lg-0 py-2">The Entertainers</span>{" "}
                      Whether we love them or hate them… We follow them on a
                      daily basis. Their business is their image, and their
                      image is what the people crave.
                    </p>
                  </div>
                  <div className="col-lg-5 col-xl-4 col-12 order-lg-1 order-0">
                    {" "}
                    <img
                      height={200}
                      src={require("../Images/suits/ACE-clubs.png")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-5"></div>
      </div>
    </section>
  );
}
