import React from "react";
import { GenerateID } from "../Utils/ID";

export default function Question(props) {
  const id = GenerateID();
  return (
    <div className="container my-4 card card-body bg-dark text-start">
      <div class="accordion accordion-flush bg-dark" id="accordionFlushExample">
        <div class="accordion-item bg-dark">
          <h2 class="accordion-header bg-dark" id="flush-headingOne">
            <a
              class="btn w-100 text-start bg-dark text-white"
              style={{ borderRadius: "0px" }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#" + id}
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              {props.question}
            </a>
          </h2>
          <div
            id={id}
            class="accordion-collapse collapse bg-dark"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body bg-dark">{props.answer}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
