import React from "react";

export default function TeamCard(props) {
  return (
    <div>
      <img width={"100%"} src={props.image} alt="" />
      <p>{props.name}</p>
      <div class="row">
        <div class="col-5 mx-auto">
          <div class="row">
            {props.insta ? (
              <div class="col-6 mx-auto">
                <a href={props.insta}>
                  <img
                    style={{ width: "100%" }}
                    src={require("../Images/Social/instagram.png")}
                  />
                </a>
              </div>
            ) : (
              ""
            )}
            {props.linkedin ? (
              <div class="col-6 mx-auto">
                <a href={props.linkedin}>
                  <img
                    style={{ width: "100%" }}
                    src={require("../Images/Social/linkedin.png")}
                  />
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
