import React from "react";
import Question from "../Components/Question";
import "../CSS/faq.css";

export default function FAQ() {
  const questions = [
    {
      question: "Which blockchain will you be using?",
      answer: "The Ethereum blockchain.",
    },
    {
      question: "What will be the mint price?",
      answer: "TBD.",
    },
    {
      question: "Will there be a pre-sale?",
      answer:
        "299 Members will be able to mint their card for a discounted price.",
    },
    {
      question: "What is the Burning pit?",
      answer:
        "The burning pit is a place where you can completely destroy your card in order to participate in the challenge or get some perks we will start proposing.",
    },
    {
      question: "How many Aces Of Ventures are available",
      answer: (
        <>
          <p>There are a total of 8899 NFts.</p>
          <ul>
            <li>8 unique Jokers</li>
            <li>80 Queens</li>
            <li>800 Kings</li>
            <li>2000 Jacks</li>
            <li>6011 Community Cards</li>
            <li>
              100 Aces will be deployed and airdropped to the winners of the
              pitch challenge
            </li>
          </ul>
        </>
      ),
    },
    {
      question: "What are your royalty fees on trade?",
      answer:
        "The team will be getting a 5% royalty fee on trades made in marketplaces (opensea, rarible).",
    },
  ];

  return (
    <section id="faq">
      <div className="container pt-5">
        <div className="row my-5"></div>
        <div className="row">
          <div className="col-lg-1 col-6 mx-auto mb-3">
            <p
              style={{ fontSize: 25, lineHeight: 1.5 }}
              className="section-title"
            >
              FAQ
            </p>
          </div>
          <div className="col-lg-10 col-12 mx-auto text-lg-start text-center">
            {questions.map((pair, index) => {
              return (
                <Question
                  question={pair.question}
                  answer={pair.answer}
                  key={index}
                />
              );
            })}
          </div>
        </div>
        <div className="row py-5"></div>
      </div>
    </section>
  );
}
