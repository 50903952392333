import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import MintPage from "./Sections/Pages/MintPage";
import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
import Main from "./Sections/Pages/Main";
import GoogleSheetsForm from "./Components/GoogleSheetsForm";
import MailingForm from "./Sections/Pages/MailingForm";

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

function App() {
  return (
    <div className="App">
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/mint" element={<MintPage />} />
            <Route path="/walletinfo" element={<MailingForm />} />
          </Routes>
        </BrowserRouter>
      </Web3ReactProvider>
    </div>
  );
}

export default App;
