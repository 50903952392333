import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { isMobile } from "../Utils/Resolution";

export default function LiveValue() {
  const [ETHToUSD, setETHToUSD] = useState(0);
  const numberOfDollars = 1000000;
  useEffect(() => {
    StartEthValue();
  }, []);

  const EtherImage = (
    <svg
      fill="#62688F"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-50 0 400 512"
      height={55}
    >
      <path d="M311.9 260.8L160 353.6 8 260.8 160 0l151.9 260.8zM160 383.4L8 290.6 160 512l152-221.4-152 92.8z" />
    </svg>
  );

  return (
    <div style={{ fontSize: "50px" }}>
      {numberWithCommas(numberOfDollars)} $
      {isMobile ? (
        <p className="etherValue" style={{ color: "gray" }}>
          {" "}
          = {numberWithCommas(Math.round(numberOfDollars / ETHToUSD))}{" "}
          {EtherImage}
        </p>
      ) : (
        <span className="etherValue" style={{ color: "gray" }}>
          {" "}
          = {numberWithCommas(Math.round(numberOfDollars / ETHToUSD))}{" "}
          {EtherImage}
        </span>
      )}
    </div>
  );

  async function StartEthValue() {
    fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
      .then((res) => res.json())
      .then((json) => {
        setETHToUSD(json.USD);
      });
    await new Promise((r) => setTimeout(r, 2000));
    StartEthValue();
  }
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
