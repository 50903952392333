import React from "react";

export default function Seperator() {
  return (
    <div
      className="container-fluid my-0"
      style={{ height: 1, backgroundColor: "#999999" }}
    ></div>
  );
}
