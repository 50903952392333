import React from "react";
import { useEffect } from "react";

export default function MailingForm() {
  useEffect(() => {
    setInterval(() => {
      document.getElementById("timestamp").value = getCurrentDate();
    }, 1000);
  }, []);
  return (
    <div
      id="mailing"
      class="container-fluid parallax"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div class="row text-center">
        <div className="container my-5">
          <form
            method="POST"
            action="https://script.google.com/macros/s/AKfycbxqD7ktcJc6QABN5wq5mUAfzvX067b8NXxmHgslbt76SIxyQV3BtGfWmTAtpad-moSe/exec"
          >
            <div class="container px-5">
              <div class="row">
                <div class="row my-3">
                  <div class="col-lg-3 col-12 mx-auto">
                    <h1>Your Wallet Information</h1>
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-lg-3 col-12 mx-auto">
                    <input
                      className="form-control"
                      name="Email"
                      type="email"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-lg-3 col-12 mx-auto">
                    <input
                      className="form-control"
                      name="Wallet Address"
                      type="text"
                      placeholder="Wallet Address"
                      required
                    />
                    <input
                      id="timestamp"
                      className="form-control"
                      name="Time"
                      type="text"
                      required
                      hidden
                    />
                  </div>
                </div>
                <div class="row my-3">
                  <div class="col-lg-2 col-6 mx-auto ">
                    <button className="btn btn-success" type="submit">
                      JOIN
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

function getCurrentDate() {
  return new Date().toUTCString();
}
