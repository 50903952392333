import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import "../CSS/Home.css";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { formatEther } from "@ethersproject/units";
import useSWR from "swr";
import LiveValue from "../Components/LiveValue";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { Link } from "react-router-dom";

export let AccountAddress = null;
export let isConnected = false;
let web3 = null;

export default function Home() {
  let ButtonView = <span></span>;

  return (
    <div className="parallax container-fluid py-5">
      <div className="row my-0 my-lg-5"></div>
      <div className="row my-0 my-lg-5"></div>
      <div className="row">
        <div className="col">
          <div className="row">
            <LiveValue />
            <span style={{ fontSize: 50 }}>
              <span
                className="text-warning"
                style={{ fontWeight: "900", minWidth: 400 }}
              ></span>{" "}
              To{" "}
              <p>
                <span className="text-warning" style={{ fontWeight: "900" }}>
                  100
                </span>{" "}
                StartUps{" "}
              </p>
            </span>
          </div>
          {/* <div class="row">
            <div class="col">
              <Link
                style={{
                  backgroundColor: "white",
                  fontSize: "40px",
                  borderRadius: "15px",
                  fontWeight: "900",
                  borderWidth: "0px",
                }}
                className="btn btn-light px-5 px-2"
                to={"/mint"}
              >
                <h1>Mint Page</h1>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
      <div className="row my-5"></div>
    </div>
  );
}
